:root {
    --default-bg: #f7f7f7;
    --default-color: #3b3b3b;
    --default-disabled-color: #bfbfbf;
    --default-theme: #4eccc4;
    --dark-bg: #263238;
    --dark-color: #c9cbcc;
    --dark-disabled-color: #576064;
    --dark-theme: #50ccc4;
    --ios-bg: #f7f7f7;
    --ios-color: #3b3b3b;
    --ios-disabled-color: #bfbfbf;
    --ios-theme: #dbdbdb;
    --android-bg: #f5f5f5;
    --android-color: #383838;
    --android-disabled-color: #bcbcbc;
    --android-theme: #31b6e7;
    --android-dark-bg: #292829;
    --android-dark-color: #c7c7c7;
    --android-dark-disabled-color: #585858;
}
.datepicker-modal {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.datepicker {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    font-size: 16px;
    text-align: center;
    font-family: arial, verdana, sans-serif;
    box-sizing: content-box;
    -webkit-font-smoothing: antialiased;
    user-select: none;
}
.datepicker .datepicker-header {
    padding: 0.25em 0.5em;
    min-height: 2em;
    line-height: 2em;
    font-size: 1.25em;
}
.datepicker .datepicker-navbar {
    padding: 0 0.5em 0.5em 0.5em;
    overflow: hidden;
}
.datepicker .datepicker-navbar-btn {
    height: 2.5em;
    line-height: 2.5em;
    float: right;
    padding: 0 1em;
    cursor: pointer;
}
.datepicker .datepicker-caption {
    display: flex;
    padding: 0.5em 0.25em;
}
.datepicker .datepicker-caption-item {
    flex: 1;
    margin: 0 0.25em;
    height: 40px;
    line-height: 40px;
    font-size: 1.2em;
}
.datepicker .datepicker-content {
    display: flex;
    padding: 0.5em 0.25em;
}
.datepicker .datepicker-col-1 {
    flex: 1;
    margin: 0 0.25em;
}
.datepicker .datepicker-viewport {
    height: 200px;
    position: relative;
    overflow: hidden;
}
.datepicker .datepicker-viewport::after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
}
.datepicker .datepicker-wheel {
    position: absolute;
    height: 40px;
    top: 50%;
    margin-top: -20px;
    width: 100%;
}
.datepicker .datepicker-scroll {
    list-style-type: none;
    padding: 0;
}
.datepicker .datepicker-scroll > li {
    height: 40px;
    line-height: 40px;
    font-size: 1.375em;
    cursor: pointer;
}
.datepicker {
   /* default */
   /* dark */
   /* ios */
   /* android */
   /* android-dark */
}
.datepicker.default {
    background-color: var(--default-bg);
}
.datepicker.default .datepicker-header {
    color: var(--default-theme);
}
.datepicker.default .datepicker-wheel {
    border-top: 1px solid var(--default-theme);
    border-bottom: 1px solid var(--default-theme);
}
.datepicker.default .datepicker-caption-item {
    color: var(--default-color);
}
.datepicker.default .datepicker-scroll li {
    color: var(--default-color);
}
.datepicker.default .datepicker-scroll li.disabled {
    color: var(--default-disabled-color);
}
.datepicker.default .datepicker-navbar-btn {
    color: var(--default-theme);
}
.datepicker.dark {
    background-color: var(--dark-bg);
}
.datepicker.dark .datepicker-header {
    color: var(--dark-theme);
}
.datepicker.dark .datepicker-wheel {
    border-top: 1px solid var(--dark-theme);
    border-bottom: 1px solid var(--dark-theme);
}
.datepicker.dark .datepicker-caption-item {
    color: var(--dark-color);
}
.datepicker.dark .datepicker-scroll li {
    color: var(--dark-color);
}
.datepicker.dark .datepicker-scroll li.disabled {
    color: var(--dark-disabled-color);
}
.datepicker.dark .datepicker-navbar-btn {
    color: var(--dark-theme);
}
.datepicker.ios {
    background-color: var(--ios-bg);
}
.datepicker.ios .datepicker-col-1 {
    margin: 0;
}
.datepicker.ios .datepicker-header {
    color: var(--ios-color);
    padding: 0 3.5em;
}
.datepicker.ios .datepicker-viewport::after {
    background: linear-gradient(#f7f7f7, rgba(245, 245, 245, 0) 52%, rgba(245, 245, 245, 0) 48%, #f7f7f7);
}
.datepicker.ios .datepicker-wheel {
    border-top: 1px solid var(--ios-theme);
    border-bottom: 1px solid var(--ios-theme);
}
.datepicker.ios .datepicker-caption-item {
    color: var(--ios-color);
}
.datepicker.ios .datepicker-scroll li {
    color: var(--ios-color);
}
.datepicker.ios .datepicker-scroll li.disabled {
    color: var(--ios-disabled-color);
}
.datepicker.ios .datepicker-navbar {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    border-bottom: 1px solid #acacac;
}
.datepicker.ios .datepicker-navbar-btn {
    color: #007aff;
}
.datepicker.ios .datepicker-navbar-btn:nth-child(2) {
    float: left;
}
.datepicker.ios .datepicker-content {
    padding-top: 48px;
}
.datepicker.ios .datepicker-header + .datepicker-content {
    padding-top: 0;
}
.datepicker.ios .datepicker-caption + .datepicker-content {
    padding-top: 0;
}
.datepicker.android, .datepicker.android-dark {
    background-color: var(--android-bg);
}
.datepicker.android .datepicker-header, .datepicker.android-dark .datepicker-header {
    color: var(--android-theme);
    border-bottom: 2px solid var(--android-theme);
}
.datepicker.android .datepicker-col-1, .datepicker.android-dark .datepicker-col-1 {
    margin: 0 0.625em;
}
.datepicker.android .datepicker-viewport::after, .datepicker.android-dark .datepicker-viewport::after {
    background-image: linear-gradient(#f5f5f5, rgba(245, 245, 245, 0) 52%, rgba(245, 245, 245, 0) 48%, #f5f5f5);
}
.datepicker.android .datepicker-wheel, .datepicker.android-dark .datepicker-wheel {
    border-top: 2px solid var(--android-theme);
    border-bottom: 2px solid var(--android-theme);
}
.datepicker.android .datepicker-caption-item, .datepicker.android-dark .datepicker-caption-item {
    color: var(--android-color);
}
.datepicker.android .datepicker-scroll li, .datepicker.android-dark .datepicker-scroll li {
    font-size: 1.125em;
    color: var(--android-color);
}
.datepicker.android .datepicker-scroll li.disabled, .datepicker.android-dark .datepicker-scroll li.disabled {
    color: var(--android-disabled-color);
}
.datepicker.android .datepicker-navbar, .datepicker.android-dark .datepicker-navbar {
    display: flex;
    border-top: 1px solid #d9d4d4;
    padding: 0;
}
.datepicker.android .datepicker-navbar-btn, .datepicker.android-dark .datepicker-navbar-btn {
    padding: 0;
    color: #000;
    flex: 1;
}
.datepicker.android .datepicker-navbar-btn:nth-child(2), .datepicker.android-dark .datepicker-navbar-btn:nth-child(2) {
    border-left: 1px solid #d9d4d4;
}
.datepicker.android-dark {
    background-color: var(--android-dark-bg);
}
.datepicker.android-dark .datepicker-viewport::after {
    background-image: linear-gradient(#282828, rgba(40, 40, 40, 0) 52%, rgba(40, 40, 40, 0) 48%, #282828);
}
.datepicker.android-dark .datepicker-caption-item {
    color: var(--android-dark-color);
}
.datepicker.android-dark .datepicker-scroll li {
    color: var(--android-dark-color);
}
.datepicker.android-dark .datepicker-scroll li.disabled {
    color: var(--android-dark-disabled-color);
}
.datepicker.android-dark .datepicker-navbar {
    border-color: #424542;
}
.datepicker.android-dark .datepicker-navbar-btn {
    color: #fff;
}
.datepicker.android-dark .datepicker-navbar-btn:nth-child(2) {
    border-color: #424542;
}
